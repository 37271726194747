(function (factory) {
    typeof define === 'function' && define.amd ? define(factory) :
    factory();
}(function () { 'use strict';

    ///////////////////////////////////
    // typing animation

    jQuery(document).ready(function($) {




        let typeText = [];
        $('.input-text').each(function(index){
            typeText[index] = $(this).find('.type-text').text();
            $(this).find('.type-text').text('');
            // console.log(typeText[index])
        });

        let bullets = [];
        $('#animate-job-description-after li').each(function(index){
            bullets[index] = $(this).find('.type-text').text();
            $(this).find('.type-text').text('');
        });

        const typingDelay = 20;
        async function delay(ms) {
            return new Promise(resolve => setTimeout(resolve, ms));
        }
          
        async function processEachLoop() {
            // loop through each input
            for (let index = 0; index < $('.input-text').length; index++) {
                let wait = index * 300;
                let targetText = $('.input-text').eq(index).find('.type-text');
                let inputText = typeText[index];
                let cursor = $('.input-text').eq(index).find('.cursor');
                cursor.addClass('typing');
            
                await delay(wait);
            
                await type(targetText, inputText);

                cursor.removeClass('typing');
            }
            // animate button click
            $('#form-animate-submit').addClass('click');
            setTimeout(function() {
                $('#form-animate-submit').removeClass('click');

                // SHOW gray box over text
                setTimeout(function() {
                    $('.input-text .type-text').addClass('active');
                }, 100 );

                // HIDE gray box over text
                setTimeout(function() {
                    $('.input-text .type-text').removeClass('active');
                    $('#animate-job-description-before').hide();
                }, 4000 );

                setTimeout(function() {
                    // show bullets
                    // animate typing bullets
                    $('#animate-job-description-after').show();
                    typeBullets();
                }, 4000);

            }, 1000);
        }

        /////////////////////////////////
        // typing animation bullets
        async function typeBullets(){
            // loop through each li in ul
            for (let index = 0; index < $('#animate-job-description-after li').length; index++) {
                let wait = index * 300;
                let targetText = $('#animate-job-description-after li').eq(index).find('.type-text');
                let inputText = bullets[index];
                let cursor = $('#animate-job-description-after li').eq(index).find('.cursor');

                // show li
                $('#animate-job-description-after li').eq(index).show();
                cursor.addClass('typing');
            
                await delay(wait);
            
                await type(targetText, inputText);

                cursor.removeClass('typing');
            }
        }
          
        async function type(target, inputText) {
            for (let charIndex = 0; charIndex < inputText.length; charIndex++) {
                target.text(target.text() + inputText.charAt(charIndex));
                await delay(typingDelay);
            }
        }
          
          
        processEachLoop();


        //////////////////////////////////////////////////
        // animate sub titles

        // Define the subTitleText array
        let subTitleText = [];
        // Iterate over each .sub-title-animate element
        $('.sub-title-animate').each(function(index){
            subTitleText[index] = $(this).text();
            console.log(subTitleText[index]);
            // subTitleHeight[index] = $(this).height() - 7;
            // $(this).height(subTitleHeight[index]);
            $(this).html('<span style="color: transparent;">&amp;</span>');

            // Create an Intersection Observer instance for each element with the percentage offset
            var observer = new IntersectionObserver(function(entries, observer) {
                entries.forEach(function(entry) {
                    let subTitleTarget = entry.target;

                    if (entry.isIntersecting) {
                        subTitleType(subTitleTarget, subTitleText[index]);
                        observer.unobserve(subTitleTarget);
                    }
                });
            });

            // Start observing the current .sub-title-animate element
            observer.observe(this);
        });

        async function subTitleType(target, inputText) {
            await delay(500);
            target.textContent = '';
            for (let charIndex = 0; charIndex < inputText.length; charIndex++) {
                const currentChar = inputText.charAt(charIndex);
                target.textContent += currentChar;
                await delay(typingDelay);
            }
        }



        //////////////////////////////////////////////////
        // animate article page accent






        //////////////////////////////////////////////////
        // primary nav toggle
        $('#mobile-nav-toggle, #close-mobile-nav').click(function(){
            $('body').toggleClass('mobile-nav-active');
            $('header').toggleClass('mobile-nav-active');
            if( $('body').hasClass('mobile-nav-active') ){
                animateMobileNav('open');
            } else {
                animateMobileNav('close');
            }
        });

        function animateMobileNav(dir){
            if( dir == 'open' ){
                $('#mobile-nav-cont').show();
                $("#mobile-navbar").show().animate({
                    right: "0"
                }, 300);
                $('.mobile-nav-bg').show().animate({
                    opacity: "1"
                }, 300);
                $('.mobile-nav-bg-inner').show().animate({
                    height: "100vh"
                }, 300);
            } else {
                $("#mobile-navbar").show().animate({
                    right: "-100vw"
                }, 300, function(){
                    $("#mobile-navbar").hide();
                });
                $('.mobile-nav-bg').show().animate({
                    opacity: "0"
                }, 300, function(){
                    $("#mobile-navbar").hide();
                });
                $('.mobile-nav-bg-inner').show().animate({
                    height: "0"
                }, 300, function(){
                    $("#mobile-navbar").hide();
                });
                setTimeout(function() {
                    $('#mobile-nav-cont').hide();
                }, 300);
                
            }
        }

    });

}));
